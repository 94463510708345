import PropTypes from 'prop-types';

const nodePropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

const textPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export { nodePropType, textPropType };
