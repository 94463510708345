import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, image, metaTitle, link }) {
  let metaImage = image ?? {
    url: process.env.GATSBY_STATIC_IMAGES_DOMAIN + '/logo-larg.png',
  };
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defer={false}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `og:image`,
          content: metaImage?.url,
        },
        {
          name: `og:image:height`,
          content: metaImage?.height,
        },
        {
          name: `og:image:width`,
          content: metaImage?.width,
        },
        {
          name: `twitter:image`,
          content: metaImage?.url,
        },
        {
          name: 'facebook-domain-verification',
          content: 'a6jip464up8yi539t5znh18yu2m7la',
        },
        {
          name: 'title',
          content: metaTitle,
        },
      ].concat(meta)}
    >
      {link && <link rel="canonical" href={link} />}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
