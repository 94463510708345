import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { nodePropType } from 'shared/propTypes';
import './button.scss';

const Button = ({
  btnType = 'button',
  handleClick,
  type,
  children,
  disabled,
  id,
  linkAsButton,
  path,
  state,
  title,
  ariaLabel,
  style,
}) =>
  !linkAsButton ? (
    <button
      type={btnType}
      id={id}
      className={`${type ? `button ${type}` : 'button'}`}
      onClick={e => _.isFunction(handleClick) && handleClick(e)}
      disabled={disabled}
      title={title}
      aria-label={ariaLabel}
      style={style}
    >
      {children}
    </button>
  ) : (
    <Link
      className={`${type ? `button ${type}` : 'button'} linkAsButton`}
      to={path}
      state={state || {}}
      onClick={e => {
        e.stopPropagation();
        if (handleClick) handleClick();
        if (path?.includes('login'))
          localStorage.setItem(
            'currentRoute',
            `${window.location.pathname}${window.location.search}`
          );
      }}
      title={title}
    >
      {children}
    </Link>
  );

Button.propTypes = {
  children: nodePropType.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  style: PropTypes.object,
};

Button.defaultProps = {
  id: '',
  style: {},
};

export default Button;
