import React from 'react';
import FloatingLabel from 'floating-label-react';
import { Field } from 'formik';

export default ({
  name,
  placeholder,
  maxLength,
  floating = true,
  showErrorMessages = true,
  disabled = false,
  autoFocus = false,
  type = 'text',
  customClass,
  onFocus = () => {},
  onBlur = () => {},
  onChange = () => {},
  autoComplete,
  onKeyDown,
  textRef,
  onClick = () => {},
}) => {
  return (
    <Field name={name}>
      {({ field, meta, form }) => (
        <div
          className={`${field.name} ${meta.touched} app-input-text`}
          ref={textRef}
        >
          {floating ? (
            <FloatingLabel
              className={`${meta.touched && meta.error ? 'error' : ''} ${
                customClass ?? ''
              }`}
              name={field.name}
              placeholder={placeholder}
              type={type}
              value={field.value || ''}
              maxLength={maxLength}
              disabled={disabled}
              autoFocus={autoFocus}
              {...field}
              onChange={evt => {
                const value = evt.currentTarget.value;
                if (value && maxLength && value.length > maxLength) {
                  return;
                }
                onChange(value);
                form.setFieldValue(name, value);
              }}
              onFocus={() => {
                onFocus();
                form.setFieldTouched(name, true);
              }}
              onBlur={() => onBlur()}
              onClick={() => onClick()}
              autoComplete={autoComplete}
              onKeyDown={onKeyDown}
              onfocusout={onKeyDown}
            />
          ) : (
            <input
              className={`${meta.touched && meta.error ? 'error' : ''}`}
              name={field.name}
              placeholder={placeholder}
              type={type}
              value={field.value || ''}
              maxLength={maxLength}
              disabled={disabled}
              autoFocus={autoFocus}
              {...field}
              ref={textRef}
              // onKeyDown={onKeyDown}
            />
          )}
          {meta.touched && meta.error && showErrorMessages && (
            <p className="error-message">{meta.error}</p>
          )}
        </div>
      )}
    </Field>
  );
};
